import Vue from 'vue'
import '@babel/polyfill'
import App from './App.vue'
import router from './router'
import { store } from './store/index.js'
import VueMask from 'v-mask'
import VueMeta from 'vue-meta'
import VueAgile from 'vue-agile'
import Croppa from 'vue-croppa'
import './assets/sass/_main.scss'
const fb = require('./firebaseConfig.js')

Vue.use(VueAgile);
Vue.use(Croppa);
Vue.use(VueMask);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(require('vue-moment'))

Vue.config.productionTip = false
Vue.config.devtools = true

let app
fb.auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      render: h => h(App)
    })
  }
})