import firebase from 'firebase/app';

require("firebase/auth")
require("firebase/storage")
require("firebase/firestore")
require("firebase/functions")
require("firebase/analytics")
require("firebase/performance")

// firebase init goes here
const firebaseConfig = {
  apiKey: "AIzaSyDXs82oAuT59mdgcA5j8mRfDz_EXF6egvI",
  authDomain: "medean95-4d3b4.firebaseapp.com",
  projectId: "medean95-4d3b4",
  storageBucket: "medean95-4d3b4.appspot.com",
  messagingSenderId: "855174670785",
  appId: "1:855174670785:web:8c0b798cc3f693eccfe740",
  measurementId: "G-RX2WRXM7PR"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics()
firebase.performance()
firebase.firestore().enablePersistence()

const db = firebase.firestore()
const firestore = firebase.firestore
const auth = firebase.auth()
const storageRef = firebase.storage().ref()
const currentUser = auth.currentUser
const functions = firebase.functions()

// firebase collections
const usersCollection = db.collection('users')
const ordersCollection = db.collection('orders')
const productsCollection = db.collection('products')
const customersCollection = db.collection('customers')

export {
  db,
  auth,
  storageRef,
  firestore,
  currentUser,
  functions,
  usersCollection,
  ordersCollection,
  productsCollection,
  customersCollection
}