<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
    <Cookies />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Cookies from '@/components/Cookies.vue'

export default {
  data: () => ({
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile', 'products']),
  },
  async mounted () {
    if (!this.products || this.products.length < 1) {
      this.$store.dispatch("getProducts")
    }
  },
  components: {
    Cookies
  },
  metaInfo: () => ({
    title: 'MedeaMP',
    titleTemplate: '%s | N95 Masks',
    htmlAttrs: {
      lang: 'en',
      amp: undefined
    },
    headAttrs: {
      test: true
    },
    bodyAttrs: {
      tabIndex: 0
    },
    meta: [
      { name: 'description', content: "N95 Masks for Governments and Government Agencies" }
    ],
  })
};
</script>