import Vue from 'vue'
import VueRouter from 'vue-router'
const fb = require('../firebaseConfig.js')

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      name: 'home',
      component: () => import('../views/Home.vue')
    },
    {
      path: '/products/:vendor',
      name: 'vendor',
      component: () => import('../views/Catalog/Vendor.vue')
    },
    {
      path: '/products/:vendor/:id',
      name: 'product',
      component: () => import('../views/Catalog/Product.vue')
    },
    {
      path: '/howtoorder',
      name: 'howToOrder',
      component: () => import('../views/HowToOrder.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/Contact.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('../views/Privacy.vue')
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('../views/Terms.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Auth/Login.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../views/Auth/Register.vue')
    },
    {
      path: '/dashboard', redirect: '/dashboard/home',
      name: 'dashboard',
      component: () => import('../views/Dashboard/Dashboard.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/dashboard/home',
          name: 'dashHome',
          component: () => import('../views/Dashboard/DashHome.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/products',
          name: 'dashProducts',
          component: () => import('../views/Dashboard/Catalog/Products.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/products/add',
          name: 'dashAddProduct',
          component: () => import('../views/Dashboard/Catalog/AddProduct.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/products/:id',
          name: 'dashEditProduct',
          component: () => import('../views/Dashboard/Catalog/EditProduct.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/customers',
          name: 'dashCustomers',
          component: () => import('../views/Dashboard/Customers/Customers.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/customers/:id',
          name: 'dashCustomer',
          component: () => import('../views/Dashboard/Customers/Customer.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/allorders',
          name: 'dashOrders',
          component: () => import('../views/Dashboard/Orders/Orders.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/allorders/:id',
          name: 'dashOrder',
          component: () => import('../views/Dashboard/Orders/Order.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/orders',
          name: 'custOrders',
          component: () => import('../views/Dashboard/CustomerOrders/Orders.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/orders/:id',
          name: 'orders',
          component: () => import('../views/Dashboard/CustomerOrders/Order.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/orders/new',
          name: 'newOrder',
          component: () => import('../views/Dashboard/CustomerOrders/NewOrder.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/dashboard/account',
          name: 'account',
          component: () => import('../views/Dashboard/Account.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = fb.auth.currentUser

  if (requiresAuth && !currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router